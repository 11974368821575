
import CloudFun, { Condition, defineComponent, Operator, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue"
import { VxeFormProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    FileUploader,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [
      { field: 'Name' },
      { field: 'Description' },
    ]

    const gridOptions: GridOptions = {
      id: 'service-category',
      stripe: false,
      title: '類別',
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: { sheetName: '類別清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '類別清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [{
        field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      { field: 'Published', title: '已發佈', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? '是' : '否' },
      { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        query: model ? (params) => {
          if (params.condition) params.condition.and('ParentId', Operator.IsNull);
          else params.condition = new Condition('ParentId', Operator.IsNull);
          return model.dispatch('serviceCategory/query', params);
        } : undefined,
        queryAll: model ? () => model.dispatch('serviceCategory/query') : undefined,
        save: model ? (params) => model.dispatch('serviceCategory/save', params) : undefined
      },
      modalConfig: { width: 550, showFooter: true },
      decideRowOperable: (row, operation) => { console.log('operable: ', row, operation); return operation !== 'delete' || row.DataMode !== 4 },
      treeConfig: { children: 'Children' }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: 'right',
      items: [
        { field: 'Name', title: '名稱', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } } },
        { field: 'Picture.Uri', title: '圖片', span: 24, slots: { default: 'column-picture-and-remark' } },
      ],
      rules: {
        Name: [{ type: 'string', required: true }],
        'Picture.Uri': [{
          validator: (params) => { if (params.itemValue && typeof params.itemValue === 'string' && !params.itemValue.startsWith('http')) return new Error('圖檔尚未上傳完畢'); }
        }]
      }
    }

    const parentIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("serviceCategory/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true },
      ],
      promises: {
        find: (value) => model!.dispatch("serviceCategory/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition) params.condition.and("ParentId", Operator.IsNull);
          else params.condition = new Condition("ParentId", Operator.IsNull);
          return model!.dispatch("serviceCategory/query", params); // eslint-disable-line
        },
      },
      treeConfig: { parent: "ParentId", children: "Children" },
    };

    return {
      grid,
      gridOptions,
      formOptions,
      parentIdSelectOptions,
      uploader,
      uploadAction: `${process.env.VUE_APP_STORAGE_SERVICE}/api/files`,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash('#batch-dropdown').dropdown('hide');
    },
    async uploaderFilter(current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
  }
});
